















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import format from '@/filter/dateFormat';
import { CHANGE_CONSERVATOR } from '../../../store/actions';
import { ChangeConservator, ConservatorshipDetails } from '@/modules/conservatorship/types';
import { ApiResponse } from '@/components/types';
import ConservatorSelect from '@/modules/conservator/components/ConservatorSelect.vue';
import ConservatorTypeSelect from '@/modules/conservatorship/components/ConservatorTypeSelect.vue';
import DatePicker from '@/components/form/DatePicker.vue';

const Conservatorship = namespace('conservatorship');

@Component({
  components: {
    DatePicker,
    ConservatorSelect,
    ConservatorTypeSelect
  }
})
export default class ConservatorChangeDialog extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
  @Conservatorship.Action(CHANGE_CONSERVATOR) changeConservator!: (params: ChangeConservator) => Promise<ApiResponse>;

  dialog: boolean = false;
  error: any = null;

  value: ChangeConservator = {
    conservator_hold: format(new Date(), 'yyyy-MM-dd'),
    conservator_id: this.conservatorship.conservator.id,
    conservator_type: this.conservatorship.conservator.type,
    conservatorship_id: this.conservatorship.id,
    reassignment: false,
  };

  @Watch('dialog')
  watchDialogClosed(dialog: boolean) {
    if (dialog) {
      this.value = {
        conservator_hold: format(new Date(), 'yyyy-MM-dd'),
        conservator_id: this.conservatorship.conservator.id,
        conservator_type: this.conservatorship.conservator.type,
        conservatorship_id: this.conservatorship.id,
        reassignment: false,
      };

      return;
    }

    setTimeout(() => {
      // @ts-ignore
      this.$refs.form.reset();
      this.error = null;
    }, 200);
  }

  save() {
    this.error = null;

    return this.changeConservator(this.value);
  }
}
