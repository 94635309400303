












import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorType } from '../types';

@Component({})
export default class ConservatorTypeSelect extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: String, default: 'select.conservator_type' }) fieldName!: string;

  list: Array<{ value: ConservatorType, text: string }> = [
    { value: 'replacement', text: 'Verhinderungsbetreuer' },
    { value: 'concerned', text: 'Hauptbetreuer' }
  ];
}
